<template>
  <v-container
    fluid
    style="background-color: #E6E6E6;"
  >
    <v-row class="py-2 mb-n2 ml-1">
      <v-col class="text-left">
        <a
          href="#"
          style="text-decoration:none;"
          @click="cancelButtonClick"
        >
          <div class="d-inline-flex">
            <v-icon color="purple">
              mdi-chevron-left
            </v-icon>
            <p
              class="text-h4 my-3 mr-10"
              style="color: purple;"
            >
              Kembali
            </p>
          </div>
        </a>
      </v-col>
    </v-row>

    <v-row class="ml-3">
      <v-col
        style="background-color: #E6E6E6;"
        class="mr-4"
      >
        <v-card
          class="px-5 py-5 mt-0 mb-3"
          style="background-color: #FFFFFF;"
        >
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <span
                class="font-weight-bold text-h4"
                style="font-size: 16px;"
              >
                {{ dataAsset.prt_name }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <span
                class="text-h4"
                style="font-color: #808080; font-size: 16px;"
              >
                {{ dataAsset.name }}
              </span>
            </v-col>
          </v-row>
        </v-card>

        <v-row>
          <v-col>
            <v-card class="px-5 py-5 mt-0 mb-0">
              <v-row>
                <v-col>
                  <span
                    class="font-weight-bold"
                    style="font-size: 16px; font-color: #333333;"
                  >
                    Informasi Umum
                  </span>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  style="font-color: #808080;"
                  class="font-weight-bold text--secondary"
                >
                  Kode Aset
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  style="font-color: #808080;"
                  class="font-weight-bold text--secondary"
                >
                  Tipe
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  style="font-color: #808080;"
                  class="font-weight-bold text--secondary"
                >
                  Ukuran
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  style="font-color: #808080;"
                  class="font-weight-bold text--secondary"
                >
                  {{ dataAsset.code }}
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  style="font-color: #808080;"
                  class="font-weight-bold text--secondary"
                >
                  {{ dataAsset.type }}
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  style="font-color: #808080;"
                  class="font-weight-bold text--secondary"
                >
                  {{ dataAsset.width }} X {{ dataAsset.height }} {{ dataAsset.size_unit }}
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card class="px-5 pt-5 pb-8 mt-0 mb-0">
              <v-row>
                <v-col
                  style="font-color: #333333;"
                  class="font-weight-bold"
                >
                  <span style="font-size: 16px;">
                    Harga Media
                  </span>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="py-0 text--secondary"
                />

                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="py-0 text--secondary"
                >
                  Harga Mitra
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="py-0 text--secondary"
                >
                  Harga Rekomendasi
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="text--secondary"
                >
                  Biaya Pasang
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="font-weight-bold text--secondary"
                >
                  Rp {{ dataAsset.price_install || 0 | dotThousandSeparator }}
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="font-weight-bold text--secondary"
                >
                  -
                </v-col>
              </v-row>

              <v-row class="mt-n3">
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="text--secondary"
                >
                  Biaya Cetak
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="font-weight-bold text--secondary"
                >
                  Rp {{ dataAsset.price_print || 0 | dotThousandSeparator }}
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="font-weight-bold text--secondary"
                >
                  -
                </v-col>
              </v-row>

              <v-row class="mt-n3">
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="text--secondary"
                >
                  Harga Tahunan
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="font-weight-bold text--secondary"
                >
                  Rp {{ dataAsset.price_year || 0 | dotThousandSeparator }}
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="font-weight-bold text--secondary"
                >
                  -
                </v-col>
              </v-row>

              <v-row class="mt-n3">
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="text--secondary"
                >
                  Pajak Tahunan
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="font-weight-bold text--secondary"
                >
                  Rp {{ dataAsset.ads_tax_year || 0 | dotThousandSeparator }}
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="font-weight-bold text--secondary"
                >
                  -
                </v-col>
              </v-row>

              <v-row class="mt-n3">
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="text--secondary"
                >
                  Harga Bulanan
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="font-weight-bold text--secondary"
                >
                  Rp {{ dataAsset.price_month || 0 | dotThousandSeparator }}
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="font-weight-bold text--secondary"
                >
                  Rp {{ dataAsset.price_month_recomm || 0 | dotThousandSeparator }}
                </v-col>
              </v-row>

              <v-row class="mt-n3">
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="text--secondary"
                >
                  Pajak Bulanan
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="font-weight-bold text--secondary"
                >
                  Rp {{ dataAsset.ads_tax_month || 0 | dotThousandSeparator }}
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="font-weight-bold text--secondary"
                >
                  -
                </v-col>
              </v-row>

              <v-row class="mt-n3">
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="text--secondary"
                >
                  Harga Harian
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="font-weight-bold text--secondary"
                >
                  Rp {{ dataAsset.price_day || 0 | dotThousandSeparator }}
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="font-weight-bold text--secondary"
                >
                  Rp {{ dataAsset.price_day_recomm || 0 | dotThousandSeparator }}
                </v-col>
              </v-row>

              <v-row class="mt-n3">
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="text--secondary"
                >
                  Pajak Harian
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="font-weight-bold text--secondary"
                >
                  Rp {{ dataAsset.ads_tax_month || 0 | dotThousandSeparator }}
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  class="font-weight-bold text--secondary"
                >
                  -
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card class="px-5 py-5 mt-0 mb-0">
              <v-row>
                <v-col>
                  <span
                    class="font-weight-bold"
                    style="font-size: 16px; font-color: #333333;"
                  >
                    Lokasi Media
                  </span>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-row>
                    <v-col class="text--secondary">
                      <span style="font-size: 16px; font-color: #808080;">
                        Alamat
                      </span>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="py-0 font-weight-bold text--secondary">
                      <span style="font-size: 16px; font-color: #808080;">
                        {{ dataAsset.ass_address_complete }}
                      </span>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="text--secondary mt-5">
                      <span style="font-size: 16px; font-color: #808080;">
                        Tampak
                      </span>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="py-0 font-weight-bold text--secondary">
                      <span style="font-size: 16px; font-color: #808080;">
                        {{ dataAsset.view_point }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col>
                  <GmapMap
                    :center="mapCoordinate"
                    :zoom="15"
                    map-type-id="roadmap"
                    style="width: auto; height: 300px;"
                    :options="mapOptions"
                  >
                    <GmapMarker
                      :position="mapCoordinate"
                    />
                  </GmapMap>
                  <a
                    target="_blank"
                    :href="'https://www.google.com/maps/search/?api=1&query=' + mapCoordinate.lat + ',' + mapCoordinate.lng"
                  >
                    Buka peta di Google Maps
                  </a>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card
              class="px-5 py-5 mt-0 mb-0"
            >
              <v-row>
                <v-col
                  style="font-color: #333333;"
                  class="px-5 py-5 mt-0 mb-0 font-weight-bold"
                >
                  <span style="font-size: 16px;">
                    Foto Media
                  </span>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <template v-if="dataAsset.photos.length > 0">
                    <tiny-slider
                      ref="tinySlider"
                      v-bind="tinySliderOptions"
                    >
                      <div
                        v-for="(photo, i) in dataAsset.photos"
                        :key="i"
                      >
                        <v-img
                          :src="photo.link"
                          style="height: 100%;"
                          lazy-src="@/assets/image-loader.png"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="purple lighten-5"
                              />
                            </v-row>
                          </template>
                        </v-img>
                      </div>
                    </tiny-slider>
                    <template v-if="dataAsset.photos.length > 3">
                      <div class="d-flex justify-space-between arrow">
                        <v-icon
                          x-large
                          style="color: #F2F2F2;"
                          @click="prev"
                        >
                          mdi-chevron-left
                        </v-icon>
                        <v-icon
                          x-large
                          style="color: #F2F2F2;"
                          @click="next"
                        >
                          mdi-chevron-right
                        </v-icon>
                      </div>

                      <div class="d-flex justify-center py-3">
                        <span
                          v-for="(n, i) in listImages"
                          :id="'dot-' + i"
                          :key="n"
                          class="dot"
                          @click="navigationClick(i)"
                        />
                      </div>
                    </template>
                  </template>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card class="px-5 pt-5 pb-8 mt-0 mb-0">
              <v-row>
                <v-col
                  style="font-color: #333333;"
                  class="font-weight-bold"
                >
                  <span style="font-size: 16px;">
                    Dokumen Media
                  </span>
                </v-col>
              </v-row>

              <template v-if="dataAsset.documents.length > 0">
                <v-row
                  v-for="(doc, i) in dataAsset.documents"
                  :key="i"
                  class="mb-n8"
                >
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <span style="font-size: 16px; color: #808080;">
                      {{ doc.name }}
                    </span>

                    <span style="font-size: 16px; color: #808080;">
                      ({{ doc.file_size }})
                    </span>

                    <v-icon
                      v-if="doc.file_path_view_only !== null"
                      @click="showModalImage(doc.file_path_view_only, doc.name, doc.file_type)"
                    >
                      mdi-eye
                    </v-icon>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="8"
                  >
                    <v-radio-group
                      v-if="doc.status === 3"
                      v-model="docValue[i]"
                      class="mx-o my-0 px-0 py-0"
                      row
                    >
                      <v-radio
                        label="Terima"
                        :value="1"
                        @click="validateEnableButton()"
                      />
                      <v-radio
                        label="Tolak"
                        :value="0"
                        color="red"
                        @click="validateEnableButton()"
                      />
                      <span
                        style="color: red; font-size: 14px; padding-top: 2px;"
                      >
                        {{ errMssg[i] }}
                      </span>
                    </v-radio-group>
                    <div
                      v-else
                      class="mx-o mb-2 pb-3"
                      style="font-size: 16px; color: #808080;"
                    >
                      {{ doc.status_string }}
                    </div>
                  </v-col>
                </v-row>
              </template>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card
              class="px-5 py-5 mt-0 mb-0"
            >
              <v-row>
                <v-col
                  style="font-color: #333333;"
                  class="px-3 py-5 mt-0 mb-0 font-weight-bold"
                >
                  <span style="font-size: 16px;">
                    Offline Order
                  </span>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="font-weight-bold">
                  Tanggal Mulai
                </v-col>
                <v-col class="font-weight-bold">
                  Durasi
                </v-col>
                <v-col><v-spacer /></v-col>
                <v-col><v-spacer /></v-col>
                <v-col><v-spacer /></v-col>
              </v-row>
              <template v-if="dataAsset.offline_order.length > 0">
                <v-row
                  v-for="(v, i) in dataAsset.offline_order"
                  :key="i"
                  class="mt-n2"
                >
                  <v-col>
                    {{ v.start_date }}
                  </v-col>
                  <v-col>
                    {{ v.duration }}
                  </v-col>
                  <v-col><v-spacer /></v-col>
                  <v-col><v-spacer /></v-col>
                  <v-col><v-spacer /></v-col>
                </v-row>
              </template>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card
              class="px-5 py-5 mt-0 mb-0"
            >
              <v-row>
                <v-col
                  style="font-color: #333333;"
                  class="px-3 py-5 mt-0 mb-0 font-weight-bold"
                >
                  <span style="font-size: 16px;">
                    Persetujuan
                  </span>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="2"
                  md="2"
                >
                  <span style="font-size: 16px;">
                    Catatan
                  </span>
                  <br>
                  <span
                    style="
                      font-size: 12px;
                      color: #333333;"
                  >
                    Harus diisi jika ada data atau dokumen yang belum sesuai
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="10"
                >
                  <v-textarea
                    ref="approvalReasonTextArea"
                    v-model="approvalReason"
                    placeholder="Catatan"
                    outlined
                    color="purple"
                    no-resize
                    class="mr-5"
                    background-color="#FAFAFA"
                    :error-messages="approvalReasonErrorMessages"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col class="text-right">
                  <template v-if="showButton">
                    <v-btn
                      :disabled="!isEnableAccButton"
                      class="text-capitalize"
                      color="primary"
                      @click="verifyButtonClick(1)"
                    >
                      <span style="font-size: 16px;">
                        Telah Sesuai
                      </span>
                    </v-btn>
                    <v-btn
                      class="text-capitalize"
                      color="red"
                      @click="verifyButtonClick(0)"
                    >
                      <span style="font-size: 16px;">
                        Belum Sesuai
                      </span>
                    </v-btn>
                  </template>

                  <template v-else>
                    <span style="color: red; margin-right: 20px;">
                      {{ dataAsset.action_btn_hide_msg }}
                    </span>
                  </template>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      width="600"
    >
      <v-card class="px-5 pt-5 pb-10">
        <v-row>
          <v-col>
            <span
              class="ml-5 font-weight-bold"
              style="font-size: 16px;"
            >
              {{ dialogTitle }}
            </span>
          </v-col>
          <v-col class="text-right">
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          class="mt-n6"
        >
          <v-col>
            <span
              class="ml-5"
              style="font-size: 14px;"
            >Untuk melihat file pastikan IDM kamu dinonaktifkan terlebih dahulu</span>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="d-flex justify-center align-center">
              <v-img
                v-if="docFileType === 'image'"
                :src="dialogURL"
                max-width="500"
                lazy-src="@/assets/image-loader.png"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="purple lighten-5"
                    />
                  </v-row>
                </template>
              </v-img>
              <iframe
                v-if="docFileType === 'pdf'"
                :src="dialogURL"
                width="100%"
                height="500"
              />
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-row justify="center">
      <v-dialog
        v-model="confirmDialog"
        max-width="360"
      >
        <v-card>
          <v-card-text class="pb-2">
            <span style="font-size: 14px;">
              {{ confirmDialogText }}
            </span>
          </v-card-text>
          <v-card-actions class="px-7 pt-1 pb-3">
            <v-row>
              <v-col>
                <v-btn
                  color="white"
                  class="text-capitalize"
                  small
                  block
                  @click="confirmButtonClick()"
                >
                  <span style="font-size: 14px;">
                    Ya
                  </span>
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  color="purple"
                  class="text-capitalize"
                  small
                  block
                  @click="confirmDialog = false"
                >
                  <span style="font-size: 14px;">
                    Tidak
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      dialog: false,
      dialogTitle: 'Loading . . .',
      dialogURL: '@/assets/image-loader.png',
      docFileType: 'image',
      confirmDialog: false,
      confirmDialogText: '',
      newStatus: undefined,
      dataAsset: {
        hash: '',
        code: '',
        status: 3,
        status_string: 'Dalam Peninjauan',
        type: 'Billboard',
        width: '5',
        height: '10',
        size_unit: 'm',
        name: '',
        verified: 0,
        price_day: 0,
        price_month: 0,
        price_year: 0,
        price_print: 0,
        price_install: 0,
        add_pro: '',
        add_kot: '',
        coordinate: {
          lat: -6.995092,
          long: 110.420914,
        },
        documents: [],
        view_point: '',
        photos: [],
        action_btn_hide_msg: '',
        offline_order: [],
        ads_tax_month: 0,
        ads_tax_year: 0,
        price_day_recomm: 0,
        price_month_recomm: 0,
      },
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      },
      approvalReasonErrorMessages: '',
      photoLink: '',
      checkBoxValues: [],
      documentParameter: {},
      isDisableCheckbox: [],
      isDisableAcceptButton: false,
      isDisableRejectButton: false,
      approvalReason: '',
      isEnableAccButton: true,
      tinySliderOptions: {
        mouseDrag: true,
        loop: false,
        items: 3,
        controls: false,
      },

      // section document:
      docValue: [null, null, null, null],
      errMssg: { 0: '', 1: '', 2: '', 3: '' },

      // show hide verify button:
      showButton: true,
    }),

    computed: {
      mapCoordinate () {
        const retVal = {}
        retVal.lat = this.dataAsset.coordinate.lat
        retVal.lng = this.dataAsset.coordinate.long
        return retVal
      },

      listImages () {
        let imageCount = 0
        if (this.dataAsset.photos) {
          imageCount = this.dataAsset.photos.length
        }
        if (imageCount > 0) {
          imageCount = Math.ceil(imageCount / 3)
        }
        return imageCount
      },
    },

    watch: {
    },

    created () {
      this.initialize(this.$route.params.id)
    },

    methods: {
      initialize (id) {
        const vm = this

        const requestBody = {
          ass_hash: id,
        }

        axios.post('/t/asset/verification/detail', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.dataAsset = res.data.data.detail
            this.setCheckBoxValues(vm.dataAsset.documents)
            if (vm.dataAsset.photos.length > 0) this.setPhotoLink(vm.dataAsset.photos[0].link)
            if (vm.dataAsset.action_btn_hide) this.showButton = false
          }
        }).catch((e) => {
        })
      },

      setCheckBoxValues (documents) {
        documents.map((val, i) => {
          if (val.status === 1) {
            this.checkBoxValues.push('1')
            this.isDisableCheckbox.push(true)
          } else {
            this.checkBoxValues.push('0')
            this.isDisableCheckbox.push(false)
          }
        })
        // this.setDisabledButton()
      },

      setDisabledButton () {
        if (this.checkBoxValues.includes('0')) {
          this.isDisableAcceptButton = true
          this.isDisableRejectButton = false
        }
        if (!this.checkBoxValues.includes('0')) {
          this.isDisableAcceptButton = false
          this.isDisableRejectButton = true
        }
      },

      setPhotoLink (link) {
        this.photoLink = link
      },

      checkboxCLick (data, i) {
        // this.setDisabledButton()
      },

      verifyButtonClick (key) {
        this.newStatus = key

        if (key === 1) {
          this.confirmDialogText = 'Apakah anda yakin menyetujui verifikasi aset ini ?'
        } else {
          this.confirmDialogText = 'Apakah anda yakin menolak verifikasi aset ini ?'
        }

        this.confirmDialog = true
      },

      confirmButtonClick () {
        const approvalReasonValue = this.approvalReason.trim()
        if (!approvalReasonValue && this.newStatus === 0) {
          this.approvalReasonErrorMessages = 'Approval Reason harus diisi jika ada data atau dokumen yang belum sesuai.'
          this.$refs.approvalReasonTextArea.focus()
          return
        }
        this.dataAsset.documents.map((val, i) => {
          if (val.status === 1) this.docValue[i] = 1
          // if (this.docValue[i] === null) {
          //   this.errMssg[i] = 'Status dokumen media tidak boleh kosong'
          // } else {
          //   this.errMssg[i] = ''
          // }
          this.documentParameter[val.type] = this.docValue[i]
        })

        // for (const key in this.errMssg) {
        //   if (this.errMssg[key] !== '') return
        // }

        const requestBody = {
          ass_hash: this.dataAsset.hash,
          new_stat: this.newStatus,
          doc_arr: JSON.stringify(this.documentParameter),
          feedback: this.approvalReason,
        }

        // console.log(requestBody)

        axios.post('/t/asset/verification/modify', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
            this.confirmDialog = false
            this.$router.push({ name: 'AssetsVerification' })
          }
        }).catch((e) => {
          this.confirmDialog = false
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
      },

      eventInputApprovalReason () {
        this.approvalReasonErrorMessages = ''
      },

      cancelButtonClick () {
        this.$router.push({ name: 'AssetsVerification' })
      },

      prev () {
        this.$refs.tinySlider.slider.goTo('prev')
      },

      next () {
        this.$refs.tinySlider.slider.goTo('next')
      },

      validateEnableButton () {
        if (this.docValue.includes(0)) {
          this.isEnableAccButton = false
        } else {
          this.isEnableAccButton = true
        }
      },

      showModalImage (link, title, fileType) {
        this.dialogURL = link
        this.dialogTitle = title
        this.docFileType = fileType
        this.dialog = true
      },

      closeModalImage () {
      },
    },
  }
</script>

<style scoped>
  #listImages {
    cursor: pointer;
  }

  .tns-item {
    padding: 5px;
    height: 250px;
  }

  /* The dots/bullets/indicators */
  .dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
    padding-top: 10px;
  }

  .dot-active, .dot:hover {
    background-color: #717171;
  }

  /* Next & previous buttons */
  .arrow {
    cursor: pointer;
    position: absolute;
    top: 50%;
    color: white;
    width: 96%;
  }
</style>
